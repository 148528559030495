import { ConnectorTabs } from '../../../../core-utils/enums/ConnectorTabs';
import { ConnectorTab } from '../../Connectors/ConnectorSettings';

export const salesforceTabs: ConnectorTab[] = [
  {
    key: ConnectorTabs.salesforceConfiguration,
    label: 'Configuration',
    systemOnly: true,
  },
  {
    key: ConnectorTabs.saleforceDetails,
    label: 'Saleforce details',
    systemOnly: true,
  },
  {
    key: ConnectorTabs.opportunities,
    label: 'Opportunities',
    systemOnly: false,
  },
  {
    key: ConnectorTabs.accountContacts,
    label: 'Accounts/Contacts',
    systemOnly: false,
  },
  { key: ConnectorTabs.status, label: 'Status', systemOnly: true },
  { key: ConnectorTabs.events, label: 'Sync logs', systemOnly: false },
  { key: ConnectorTabs.locationCode, label: 'Location', systemOnly: false },
];
