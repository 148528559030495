import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Icon, useTranslation } from 'common';
import { useGetApprovalGroupsByUser } from 'app/src/services/approvals';

import { NavRoute } from './sideNavBar';
import { BILLING, SETTINGS } from '../../../core-utils/routes';
import {
  useIsCurrentUserAdmin,
  useIsSuperAdmin,
} from '../../../core-utils/helperFunctions/userServiceHelper';
import NbUrgentContractsChiclet from './NbUrgentContractsChiclet';
import { TaskInbox } from '../../TaskInbox/TaskInbox';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { useConnection } from 'app/src/pages/Settings/Connectors/ConnectionProvider';

interface Props {
  isNested: boolean;
  navRoute: NavRoute;
  onRouteChange: (routeObj: NavRoute) => void;
  route: string;
  selectedRoute: any;
}

const NavRouteComponent = ({
  navRoute,
  route,
  selectedRoute,
  isNested,
  onRouteChange,
}: Props) => {
  const { tk } = useTranslation();
  const isAdmin = useIsCurrentUserAdmin();
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');
  const isSuperAdmin = useIsSuperAdmin();

  const { data: approvalGroups } = useGetApprovalGroupsByUser({
    enabled: !isSuperAdmin,
  });

  const isBillingRoute = navRoute.route === BILLING;
  const isSettingsRoute = navRoute.route === SETTINGS;
  const className = clsx(
    'relative flex justify-between w-full rounded shadow-[0_0_#0000] cursor-pointer h-12 px-0 py-2',
    selectedRoute === navRoute.route
      ? `${isNested ? 'bg-blue-lighter' : 'bg-blue-lightest'} text-blue`
      : 'text-black hover:text-blue'
  );

  const containerClassName = clsx(
    'flex items-center',
    selectedRoute === navRoute.route &&
      route === '/proposals' &&
      'bg-blue-lightest'
  );

  const isShowingTaskInbox =
    !isSuperAdmin &&
    route === '/proposals' &&
    ((approvalGroups && approvalGroups.length > 0) || isAdmin) &&
    canUpdate;

  const { icon: IconComponent } = navRoute;

  const connection = useConnection();

  const hasConnectionIssue = () => {
    return Object.entries(connection)
      .filter(([key]) => key !== 'updateConnection')
      .some(([, value]) => !value.isConnected);
  };

  return (
    <div className={containerClassName}>
      <Link
        className={className}
        data-testid={navRoute.route.replace('/', '')}
        id={navRoute.route}
        key={navRoute.route}
        onClick={() => onRouteChange(navRoute)}
        target={navRoute.isExternalLink ? '_blank' : undefined}
        to={route}
      >
        <div className="flex items-center">
          <div className="flex justify-center w-12 flex-0">
            <IconComponent height={32} width={32} />
          </div>
          <div className="flex flex-col">{tk(navRoute.label)}</div>
        </div>
        {isSettingsRoute && hasConnectionIssue() && (
          <div className="flex justify-center w-12 flex-0">
            <Icon.Alert className="text-red" width={32} height={32} />
          </div>
        )}
        {!isSuperAdmin && isBillingRoute && <NbUrgentContractsChiclet />}
      </Link>

      {/* this needs to be outside to deal with nested anchor tags */}
      {isShowingTaskInbox && (
        <div style={{ marginRight: '10px' }}>
          <TaskInbox />
        </div>
      )}
    </div>
  );
};

export default NavRouteComponent;
