import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  capitalizeFirstLetter,
  DefaultZeroState,
  Drawer,
  Filter,
  formatNullableFormattedAmount,
  getIconCell,
  getIconDataBasedOnProposalType,
  Icon,
  IconWithTooltip,
  ItemIcon,
  pluralize,
  ProposalSummary,
  pushIf,
  QueryCell,
  QueryTable,
} from 'common';
import StatusLabel from '../../../components/StatusLabel';
import Page from '../../../components/Page';
import AddProposalButton from '../ProposalEditor/AddProposalButton/AddProposalButton';
import PreviewDialog from '../ProposalEditor/PreviewDialog/PreviewDialog';
import { GET_ALL_PROPOSALS_KEY } from '../../../services/proposal';
import ProposalEvents from '../ProposalEditor/ActivityDrawer/ProposalEvents';
import ProposalOptionsButton from './ProposalOptionsButton';
import ProposalSettingsDrawer from './ProposalSettingsDrawer';
import { apiClient } from '../../../services/httpClients/app';
import {
  FLAG_TYPES,
  isActionAllowed,
  useCurrentUser,
  useIsCurrentUserAdmin,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import ActivityCell from '../../../components/ActivityCell';
import { Alert } from 'antd';

import styles from './ProposalListPage.module.scss';
import { useDocusignEnvelopeQuotaStatus } from '../../../services/api/docusign/docusign';
import { useConnection } from '../../Settings/Connectors/ConnectionProvider';
import {
  INTEGRATIONS_AND_CONNECTORS,
  SETTINGS_AVALARA,
  SETTINGS_CLOSE,
  SETTINGS_HUBSPOT,
  SETTINGS_QUICKBOOKS,
  SETTINGS_SALESFORCE,
} from 'app/src/core-utils/routes';
import { ClearLegacyCheckoutButton } from '../ProposalEditor/ClearLegacyCheckoutButton/ClearLegacyCheckoutButton';
import { useAuthState } from 'app/src/Auth';

const ProposalListPage: React.FC = () => {
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [activityDialogState, setActivityDialogState] = useState(false);
  const [isSettingsShowing, setIsSettingsShowing] = useState(false);
  const [proposal, setProposal] = useState<ProposalSummary | undefined>();
  const canCreate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'create');
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');

  const navigate = useNavigate();
  const { envelopeQuotaStatus } = useDocusignEnvelopeQuotaStatus();
  const { auth } = useAuthState();

  const tableRowOnClick = (selectedProposal: ProposalSummary) => {
    navigate(`/proposals/${selectedProposal.id}`);
  };

  const cells: QueryCell<ProposalSummary>[] = [
    getIconCell(ItemIcon.Proposal, {
      renderCell: (value: ProposalSummary) => {
        const draftClasses = clsx({
          'opacity-30 grayscale': value.status === 'draft',
        });

        const iconData = getIconDataBasedOnProposalType(value);

        return (
          <IconWithTooltip
            icon={iconData.icon}
            className={draftClasses}
            tooltipText={iconData.tooltipText}
          />
        );
      },
    }),
    {
      key: 'customer.name,name',
      headerLabel: ['Customer', 'Proposal title'],
      renderCell: ({ customer, name }: ProposalSummary) => (
        <div>
          <div className="truncate">{customer ? customer.name : '-'}</div>
          <div className="text-xs text truncate">{name || '-'}</div>
        </div>
      ),
      overflowCell: true,
      sortable: true,
    },
    {
      key: 'amount',
      headerLabel: ['Value', 'Term'],
      width: '15%',
      align: 'right',
      renderCell: ({ amountFormatted, termQty, termType }: ProposalSummary) => (
        <div>
          <div>{formatNullableFormattedAmount(amountFormatted)}</div>
          <div className="text-xs text-black">
            {pluralize(termQty, termType)}
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      key: 'lastEvent.createdAt',
      headerLabel: 'Last activity',
      width: 150,
      overflowCell: true,
      renderCell: (data: ProposalSummary) => (
        <ActivityCell
          onClick={() => {
            setProposal(data);
            setActivityDialogState(true);
          }}
          timeAgo={data.lastEvent?.createdAt}
          person={data.lastEvent?.contact || data.lastEvent?.user}
        />
      ),
      sortable: true,
      clickable: true,
    },
    {
      key: 'displayStatus',
      headerLabel: 'Status',
      width: '250px',
      renderCell: ({ status, displayStatus }: ProposalSummary) => (
        <StatusLabel
          status={status}
          displayStatus={displayStatus}
          feature="proposal"
          classNames="w-full"
        />
      ),
      sortable: true,
    },
    {
      key: 'signingOrderBy',
      headerLabel: 'Signed',
      width: '60px',
      renderCell: ({ options, signingDocument }: ProposalSummary) => {
        if (options?.requiresSigning) {
          if (signingDocument && signingDocument.signedAt) {
            return <Icon.SignableSigned />;
          }

          return <Icon.SignableUnsigned />;
        }

        return null;
      },
    },
    ...pushIf(canUpdate, {
      key: 'options',
      headerLabel: 'Options',
      clickable: true,
      width: 64,
      renderCell: (data: ProposalSummary) => (
        <ProposalOptionsButton
          proposal={data}
          onClickViewActivity={() => {
            setProposal(data);
            setActivityDialogState(true);
          }}
          onClickViewPreview={() => {
            setProposal(data);
            setIsPreviewDialogOpen(true);
          }}
          onClickSettings={() => {
            setProposal(data);
            setIsSettingsShowing(true);
          }}
          showDelete={data.status === 'draft'}
        />
      ),
    }),
  ];

  enum ProposalDisplayStatuses {
    approved = 'Approved',
    cancelled = 'Cancelled',
    completed = 'Completed',
    draft = 'Draft',
    expired = 'Expired',
    pending_approval = 'Pending approval',
    shared_active = 'Shared - awaiting payment',
    shared_schedule_selection = 'Shared - awaiting signature',
    signed_awaiting_payment = 'Signed - awaiting payment',
    shared_payment_selection = 'Signed - subscription awaiting payment',
  }

  enum ProposalTypes {
    initial = 'New',
    change = 'Change',
    renewal = 'Renewal',
  }

  const filterOptions: Filter[] = [
    {
      type: 'search',
      key: 'search',
      searchFields: ['customer.name', 'name'],
    },
    {
      type: 'dropdown',
      key: 'displayStatus',
      humanReadableName: 'Status',
      fieldName: 'displayStatus',
      options: Object.keys(ProposalDisplayStatuses).map((key) => ({
        value: key,
        name: ProposalDisplayStatuses[
          key as keyof typeof ProposalDisplayStatuses
        ].valueOf(),
      })),
    },
    {
      type: 'dropdown',
      key: 'proposalType',
      humanReadableName: 'Type',
      fieldName: 'proposalType',
      options: Object.keys(ProposalTypes).map((key) => ({
        value: key,
        name: ProposalTypes[key as keyof typeof ProposalTypes].valueOf(),
      })),
    },
    {
      type: 'valueRadio',
      key: 'amount',
      humanReadableName: 'Value',
      fieldName: 'amount',
      prefix: '$',
    },
    {
      type: 'dateRadio',
      key: 'createdAt',
      humanReadableName: 'Created date',
      fieldName: 'createdAt',
      optionsType: 'currentAndPast',
    },
    {
      type: 'dateRadio',
      key: 'lastEvent.createdAt',
      humanReadableName: 'Last activity',
      fieldName: 'lastEvent.createdAt',
      optionsType: 'currentAndPast',
    },
    ...pushIf<Filter>(useIsCurrentUserAdmin(), {
      type: 'toggle',
      key: 'owner.id',
      humanReadableName: 'My proposals',
      fieldName: 'owner.id',
      value: useCurrentUser()?.id!,
    }),
    {
      type: 'toggle',
      key: 'include_archived',
      humanReadableName: 'Include archived',
      fieldName: 'include_archived',
      separateQueryParam: true,
      value: 'true',
    },
  ];

  const connection = useConnection();

  const isAdmin = useIsCurrentUserAdmin();

  const renderAlert = (
    isConnected: boolean,
    isLoading: boolean,
    serviceName: string,
    reauthenticateLink: string,
    hasIssue: boolean
  ) => {
    if (!isConnected && !isLoading && isAdmin && hasIssue) {
      return (
        <Alert
          banner
          className={styles.alertBreakout}
          closable
          key={serviceName}
          message={
            connection.quickbooksConnection.connectionStatus?.errorCode ===
            'QUICKBOOKS_CUSTOM_TXN_NUMBERS_DISABLED' ? (
              <span>
                Quickbooks failed to connect. Please enable 'Custom Transaction
                Numbers' preference in QuickBooks and reconnect{' '}
                <Link to={SETTINGS_QUICKBOOKS} className="text-blue underline">
                  here
                </Link>
                .{' '}
              </span>
            ) : (
              <span>
                {capitalizeFirstLetter(serviceName)} failed to connect. Please
                reauthenticate{' '}
                <Link to={reauthenticateLink} className="text-blue">
                  here
                </Link>{' '}
                to resume syncing data.
              </span>
            )
          }
          type="error"
        />
      );
    }
    return null;
  };

  return (
    <Page
      leftWidget="Proposals"
      rightWidget={
        <div
          style={{
            display: 'inline-flex',
          }}
        >
          {auth.isSystemAdmin && (
            <div style={{ padding: '0 10px 0 0' }}>
              <ClearLegacyCheckoutButton />
            </div>
          )}
          <AddProposalButton isHidden={!canCreate} />
        </div>
      }
    >
      {envelopeQuotaStatus.quotaStatus && (
        <Alert
          banner
          className={styles.alertBreakout}
          closable
          message={`${envelopeQuotaStatus.quotaStatus}: ${envelopeQuotaStatus.quotaDescription}`}
          type="error"
        />
      )}

      {Object.entries(connection)
        .filter(([key]) => {
          return key !== 'updateConnection';
        })
        .map(([key, connState]) => {
          let settings;
          switch (key) {
            case 'hubspotConnection':
              settings = SETTINGS_HUBSPOT;
              break;
            case 'salesforceConnection':
              settings = SETTINGS_SALESFORCE;
              break;
            case 'quickbooksConnection':
              settings = SETTINGS_QUICKBOOKS;
              break;
            case 'avalaraConnection':
              settings = SETTINGS_AVALARA;
              break;
            case 'closeConnection':
              settings = SETTINGS_CLOSE;
              break;
            default:
              settings = INTEGRATIONS_AND_CONNECTORS;
          }

          return renderAlert(
            connState.isConnected,
            connState.isLoading,
            key.replace('Connection', ''),
            settings,
            !!connState.connectionStatus?.errorCode && connState.isEnabled
          );
        })}

      <QueryTable<ProposalSummary>
        queryKey={GET_ALL_PROPOSALS_KEY}
        cells={cells}
        fetchList={apiClient.getProposals}
        baseSearchValues={[
          [{ key: 'proposalType', comparator: '!', value: 'template' }],
        ]}
        filters={filterOptions}
        onRowClick={tableRowOnClick}
        dataTestId="proposal-table"
        minWidth="900px"
        zeroState={
          <DefaultZeroState
            dataTestId="no-proposals"
            message="There are no proposals available to view."
          />
        }
      />
      {proposal && (
        <PreviewDialog
          proposal={proposal}
          isOpen={isPreviewDialogOpen}
          setIsClosed={() => setIsPreviewDialogOpen(false)}
        />
      )}
      <Drawer
        noPadding
        isOpen={activityDialogState}
        onClose={() => setActivityDialogState(false)}
        header="Activity"
      >
        {proposal && (
          <ProposalEvents
            proposalId={proposal.id}
            isOpen={activityDialogState}
          />
        )}
      </Drawer>
      {proposal && (
        <ProposalSettingsDrawer
          proposal={proposal}
          isOpen={isSettingsShowing}
          onClose={() => setIsSettingsShowing(false)}
        />
      )}
    </Page>
  );
};

export default ProposalListPage;
