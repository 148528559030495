import React, { useEffect } from 'react';
import {
  BillingPeriodsType,
  Drawer,
  getBillingPeriodLabelAdverb,
  getProductBillingPeriodPrice,
  Item,
  ItemOverride,
  mergeLinesToOverrides,
  Proposal,
  QuantityOverride,
  ScheduleLine,
  splitLinesFromBillingPeriod,
  splitMergedToLines,
  useTranslation,
} from 'common';

import TimeBasedPricingFooter from './TimeBasedPricingFooter';

import styles from './TimeBasedPricing.module.scss';
import TimeBasedSchedule from './TimeBasedSchedule';
import { useProposalConfig } from '../../../../../../services/orgDefaults';

interface Props {
  billingPeriod: BillingPeriodsType;
  currentOverrideSchedule?: ScheduleLine[];
  handleUpdateOverrideSchedule: (
    bp: BillingPeriodsType,
    scheduleDraft?: ItemOverride[],
    quantityScheduleDraft?: QuantityOverride[]
  ) => void;
  isOpen: boolean;
  item: Item;
  onClose: () => void;
  proposal: Proposal;
  resetSavedScheduleDraft: (
    bp: BillingPeriodsType,
    scheduleDraft?: ItemOverride[]
  ) => void;
}

export function TimeBasedPricingDrawer({
  isOpen,
  item,
  onClose,
  handleUpdateOverrideSchedule,
  resetSavedScheduleDraft,
  billingPeriod,
  currentOverrideSchedule,
  proposal,
}: Props): React.ReactNode {
  const { tk } = useTranslation();

  const [draftSchedule, setDraftSchedule] = React.useState<
    ScheduleLine[] | undefined
  >(undefined);

  const { showDiscountViewAsDefault: isHideDiscountToggle } =
    useProposalConfig();

  // if the item has non-schedule overrides, we need to find the price for the current billing period
  const existingOverride = item.overrides?.find(
    (override) => override.billingPeriod === billingPeriod && !override.duration
  );
  const { product } = item;

  const overrideRange = proposal.overrideRanges.find(
    (range) => range.billingPeriod === billingPeriod
  );

  const basePrice = getProductBillingPeriodPrice(
    product,
    proposal.currency,
    billingPeriod
  );

  const resetSchedule = () => {
    setDraftSchedule(undefined);
    resetSavedScheduleDraft(billingPeriod);
    onClose();
  };

  const submit = () => {
    if (draftSchedule) {
      const {
        overrides: mergedDraft,
        quantityOverrides: mergedQuantityOverrides,
      } = mergeLinesToOverrides(draftSchedule);
      if (
        mergedDraft.length > 1 ||
        mergedDraft[0]?.price?.amount !== basePrice.amount ||
        mergedQuantityOverrides.length > 1 ||
        mergedQuantityOverrides[0]?.quantity !== item.quantity
      ) {
        handleUpdateOverrideSchedule(
          billingPeriod,
          mergedDraft,
          mergedQuantityOverrides
        );
        onClose();
      } else {
        resetSchedule();
      }
    }
  };

  useEffect(() => {
    const draft: ScheduleLine[] = currentOverrideSchedule
      ? splitMergedToLines(
          proposal.termQty,
          proposal.termType,
          basePrice,
          currentOverrideSchedule,
          proposal.freeMonths,
          item.quantity
        )
      : splitLinesFromBillingPeriod(
          billingPeriod,
          proposal.termQty,
          proposal.termType,
          basePrice,
          existingOverride
            ? existingOverride.listPrice || basePrice
            : basePrice,
          existingOverride ? existingOverride.price || basePrice : basePrice,
          isHideDiscountToggle ?? false,
          proposal.freeMonths
        );
    setDraftSchedule(draft);
  }, [currentOverrideSchedule, billingPeriod, proposal.startDate]);

  return (
    <Drawer
      className={styles.timeBasedPricing}
      footer={
        <TimeBasedPricingFooter
          billingPeriod={billingPeriod}
          draftSchedule={draftSchedule}
          item={item}
          onClose={onClose}
          proposal={proposal}
          resetSchedule={resetSchedule}
          submit={submit}
        />
      }
      header={`${tk(
        'Customize pricing'
      )} ${getBillingPeriodLabelAdverb(billingPeriod)}`}
      isOpen={isOpen}
      noPadding
      onClose={onClose}
      width={800}
    >
      <div className={styles.drawerContent}>
        <div className={styles.itemHeader}>
          <h3 className={styles.productName}>{item.name}</h3>
        </div>
        <div className={styles.scheduleSection}>
          {draftSchedule && (
            <TimeBasedSchedule
              currency={proposal.currency}
              scheduleRows={draftSchedule}
              // if there is an override, use that, otherwise use the price from the product
              // utilizing the override price will create an override for every period in the term length
              // on save/schedule with a duration of the term length (if no further adjustments made)
              item={item}
              overrideRange={overrideRange}
              quantity={item.quantity}
              setDraftSchedule={setDraftSchedule}
              unitPrice={
                existingOverride?.price
                  ? existingOverride.price.amount || basePrice.amount || 0
                  : basePrice.amount || 0
              }
            />
          )}
        </div>
      </div>
    </Drawer>
  );
}
