import {
  BillingPeriodsType,
  Button,
  Checkbox,
  getLevelDescription,
  getPricingDurations,
  getProductBillingPeriod,
  getProductBillingPeriodLabel,
  getProductBillingPeriodPrice,
  Icon,
  isArrayNonEmpty,
  isDefined,
  Item,
  ItemOverride,
  ProductLevel,
  Proposal,
  QuantityOverride,
  ScheduleLine,
  Select,
  useTranslation,
} from 'common';
import React, { useEffect, useState } from 'react';
import styles from './BillingPeriodPriceManagement.module.scss';
import { TimeBasedPricingTieredDrawer } from '../TimeBasedPricing/TimeBasedPricingTieredDrawer/TimeBasedPricingTieredDrawer';
import {
  getItemOverrideWithLevelsDraft,
  getLevelPrice,
  isActiveTier,
} from '../TimeBasedPricing/TimeBasedPricingTieredDrawer/utils';
import TierAdjustmentScheduleOverview from '../TimeBasedPricing/TimeBasedPricingTieredDrawer/TierAdjustmentScheduleOverview';
import { BillingPeriodString } from '../../../PaymentPlan/PaymentPlanContext';
import PriceOverride from './PriceOverride';
import clsx from 'clsx';
import { useProposalConfig } from '../../../../../services/orgDefaults';

interface Props {
  billingPeriod: BillingPeriodString;
  handleSetOverride: (arg: ItemOverride) => void;
  isSaving: boolean;
  isValidOverride: (override: ItemOverride) => boolean;
  item: Item;
  itemBillingPeriods: BillingPeriodString[];
  onUpdateOverrides: (
    itemId: string,
    overrides: ItemOverride[] | undefined,
    quantityOverrides?: QuantityOverride[]
  ) => void;
  proposal: Proposal;
  scheduleDraft: Record<BillingPeriodsType, ScheduleLine[] | undefined>;
  setScheduleDraft: (
    arg: Record<BillingPeriodsType, ScheduleLine[] | undefined>
  ) => void;
}

const TieredProductManagement = ({
  billingPeriod: selectedBillingPeriod,
  handleSetOverride,
  isSaving,
  isValidOverride,
  item,
  itemBillingPeriods,
  onUpdateOverrides,
  proposal,
  scheduleDraft,
  setScheduleDraft,
}: Props) => {
  const { tk } = useTranslation();
  const [isScheduleDrawerOpen, setIsScheduleDrawerOpen] = useState(false);

  const { product } = item;
  const allowListPriceAdjustments = item.product.listPriceAdjustable;

  const [isMarkedAsDiscount, setIsMarkedAsDiscount] = useState<boolean>(false);

  const [billingPeriod, setBillingPeriod] = useState(
    getProductBillingPeriod(product, selectedBillingPeriod || 'month')
  );

  const { showDiscountViewAsDefault: isHideDiscountToggle } =
    useProposalConfig();

  const isOneTime = product.recurrence === 'one_time';
  const { levels } = product;
  const filteredBillingPeriods: BillingPeriodsType[] = itemBillingPeriods
    .filter((bp) => bp && product.billingPeriods?.includes(bp))
    .map((bp): BillingPeriodsType => bp! as BillingPeriodsType);

  const overrideItem = getItemOverrideWithLevelsDraft(
    item,
    proposal.currency,
    billingPeriod
  );

  useEffect(() => {
    setIsMarkedAsDiscount(!!overrideItem.discount);
  }, [billingPeriod, overrideItem]);

  const changeIsMarkedAsDiscount = (checked: boolean) => {
    setIsMarkedAsDiscount(checked);

    handleSetOverride({
      ...overrideItem,
      // all overrides are discounts when ppp flag is off,
      // as there is no checkbox to control it
      discount: checked,
    });
  };

  const keyPrefix = `discount-${item.id}-`;

  const openScheduleDrawer = () => {
    setIsScheduleDrawerOpen(true);
  };
  const handleSetScheduleDraft = (
    bp: BillingPeriodsType,
    draft?: ItemOverride[],
    quantityScheduleDraft?: QuantityOverride[]
  ) => {
    const currentDraft = { ...scheduleDraft };

    currentDraft[bp] =
      draft || quantityScheduleDraft
        ? getPricingDurations(
            proposal.termQty,
            proposal.termType,
            getProductBillingPeriodPrice(item.product, proposal.currency, bp),
            proposal.freeMonths,
            draft,
            quantityScheduleDraft,
            item.quantity
          )
        : undefined;

    setScheduleDraft(currentDraft);
  };

  const resetSavedScheduleDraft = (bp: BillingPeriodsType) => {
    const currentDraft = { ...scheduleDraft };
    currentDraft[bp] = undefined;
    handleSetScheduleDraft(bp, undefined);
    const billingPeriodOverrides = item.overrides
      ? item.overrides.filter((override) => isValidOverride(override))
      : [];
    const scheduleOverrides = Object.values(currentDraft)
      .filter((overrides) => overrides && overrides.length > 0)
      .filter(isDefined);
    const scheduleOverridesFiltered = scheduleOverrides
      .flat()
      .filter((override) => {
        const productPrice = getProductBillingPeriodPrice(
          product,
          proposal.currency,
          override.billingPeriod
        );
        return productPrice.amount !== override.price.amount;
      });

    const finalResetPriceOverrides = [
      ...billingPeriodOverrides,
      ...scheduleOverridesFiltered,
    ].map((override) => {
      if (allowListPriceAdjustments) return override;

      const { listPrice, levels: previousLevels, ...rest } = override;

      const updatedLevels = previousLevels?.map((level) => {
        const { listPrice: levelListPrice, ...levelRest } = level;
        return levelRest;
      });

      return { levels: updatedLevels, ...rest };
    });

    const finalResetQuantityOverrides =
      item.quantityOverrides?.filter((qo) => qo.billingPeriod !== bp) || [];

    onUpdateOverrides(
      item.id,
      finalResetPriceOverrides,
      finalResetQuantityOverrides
    );
  };
  const getNextLevel = (productLevels: ProductLevel[], index: number) =>
    productLevels.length > index + 1 ? productLevels[index + 1] : null;

  const currentScheduleDraft = scheduleDraft[billingPeriod];
  const supportsRamping = !isOneTime && product.recurrence !== 'usage';

  return (
    <div>
      <div>
        {!isOneTime &&
          (product.billing === 'fixed' ? (
            <div className={styles.fixedBillingPeriod}>
              <p>
                {isArrayNonEmpty(filteredBillingPeriods)
                  ? getProductBillingPeriodLabel(filteredBillingPeriods[0])
                  : ''}
              </p>
            </div>
          ) : (
            <div className={styles.select}>
              <Select
                dataTestId="tier-billing-period-selection"
                onChange={(bp) => setBillingPeriod(bp)}
                options={filteredBillingPeriods.map((billP) => ({
                  value: billP,
                  name: getProductBillingPeriodLabel(billP),
                }))}
                value={billingPeriod}
              />
            </div>
          ))}
        {!scheduleDraft[billingPeriod] && ( // cannot set top level setting if schedule is set
          <Checkbox
            className="pb-6"
            label={tk(
              isHideDiscountToggle
                ? 'Hide discounts'
                : 'Display price reductions as a discount'
            )}
            name="show-as-discount-checkbox"
            onChange={(value) =>
              changeIsMarkedAsDiscount(isHideDiscountToggle ? !value : value)
            }
            value={
              isHideDiscountToggle ? !isMarkedAsDiscount : isMarkedAsDiscount
            }
          />
        )}
      </div>
      {!isOneTime && (
        <React.Fragment key={`discounts-${billingPeriod}`}>
          <TimeBasedPricingTieredDrawer
            billingPeriod={billingPeriod}
            currentOverrideSchedule={scheduleDraft[billingPeriod]}
            handleUpdateOverrideSchedule={handleSetScheduleDraft}
            isOpen={isScheduleDrawerOpen}
            item={item}
            onClose={() => setIsScheduleDrawerOpen(false)}
            overrideItem={overrideItem}
            proposal={proposal}
            resetSavedScheduleDraft={resetSavedScheduleDraft}
          />
        </React.Fragment>
      )}
      {currentScheduleDraft ? (
        <TierAdjustmentScheduleOverview
          basePrices={
            product.levels?.map((_, index) => {
              return getLevelPrice(
                product.entries,
                proposal.currency,
                billingPeriod,
                index,
                overrideItem.levels
              );
            }) || []
          }
          billingPeriod={billingPeriod}
          entries={product.entries}
          mergedSchedule={currentScheduleDraft}
          pricing={product.pricing}
          tiers={product.levels}
        />
      ) : (
        levels?.map((level, index) => {
          const activeTier = isActiveTier(item, index, item.quantity || 0);

          return (
            <div
              className={clsx(styles.tierRow, activeTier && styles.activeTier)}
              key={`${keyPrefix}edit-${billingPeriod}-${level.start}-${item.updatedAt}`}
            >
              <div className={styles.tierLevel}>
                Tier: {getLevelDescription(level, getNextLevel(levels, index))}
              </div>
              <div className={styles.inputSection}>
                <PriceOverride
                  billingPeriod={billingPeriod}
                  currency={proposal.currency}
                  handleSetOverride={handleSetOverride}
                  isSaving={isSaving}
                  item={item}
                  level={index}
                />
              </div>
            </div>
          );
        })
      )}
      {supportsRamping && (
        <Button
          className={styles.buttonWithSchedule}
          dataTestId={`schedule-discount-${billingPeriod}`}
          iconBefore={Icon.Activity}
          label={
            scheduleDraft[billingPeriod]
              ? tk('View schedule details')
              : tk('Schedule price')
          }
          onClick={openScheduleDrawer}
          type={scheduleDraft[billingPeriod] ? 'secondary' : 'primary'}
        />
      )}
    </div>
  );
};

export default TieredProductManagement;
