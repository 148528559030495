import { useQueryClient } from '@tanstack/react-query';
import PdfButton from 'app/src/components/ActionBarButtons/PdfButton';
import SubscriptionButton from 'app/src/components/ActionBarButtons/SubscriptionButton';
import WordButton from 'app/src/components/ActionBarButtons/WordButton';
import {
  FLAG_TYPES,
  isActionAllowed,
  useIsCurrentUserAdmin,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { PROPOSALS } from 'app/src/core-utils/routes';
import { useCompanyInfo } from 'app/src/services/api/branding/companyInfo';
import {
  useApprovalRequiredForProposal,
  useDeleteProposal,
} from 'app/src/services/proposal';
import {
  ApprovalRequest,
  arrayTop,
  buildFileName,
  Button,
  canAcceptProposalForBuyer,
  CreateProposalRequest,
  DropdownButton,
  formatTimeAgo,
  getIconBasedOnProposalType,
  hasAnAcceptedProposalStatus,
  isApprovedProposalStatus,
  isAutoRenewedGeneratedProposal,
  isChangeProposalType,
  isDraftProposalStatus,
  isExpiredProposalStatus,
  isProposalCancellable,
  isRenewalProposalType,
  Proposal,
  SIZE_LARGE,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ActionBar from '../../../../components/ActionBar';
import ActionBarButton from '../../../../components/ActionBarButton';
import Editable from '../../../../components/Editable';
import ExternalEntityLink from '../../../../components/ExternalEntityLink';
import StatusLabel from '../../../../components/StatusLabel';
import { apiClient, ssrAxios } from '../../../../services/httpClients/app';
import {
  createProposalEvent,
  useAcceptProposal,
} from '../../../../services/proposal';
import ProposalOptionsButton from '../../ProposalList/ProposalOptionsButton';
import VerifyProposal from '../ProposalShare/VerifyProposal';
import {
  findSchedule,
  getIsProductLinkRequired,
  getIsProductNotLinked,
  getPublishStatus,
  isProposalPublished,
  isProposalTemplate,
  proposalHasRecipients,
  useValidateProposal,
  ValidationAction,
} from '../proposalUtilities';
import styles from './EditorHeader.module.scss';
import NameModal from './NameModal';
import ProposalActivityButton from './ProposalActivityButton';
import ProposalCancelButton from './ProposalCancelButton';
import ProposalPreviewButton from './ProposalPreviewButton';
import ProposalStatus from './ProposalStatus';
import { SubscriptionChangeModal } from './SubscriptionChangeModal';
import { SubscriptionCreateModal } from './SubscriptionCreateModal';
import { SubscriptionRenewalModal } from './SubscriptionRenewalModal';
import { useContract } from '../../../../services/contract';
import { Alert } from 'antd';

interface Props {
  approvalRequest?: ApprovalRequest | null;
  isDisabled?: boolean;
  isSaving: boolean;
  onChange: (updateProposalRequest: CreateProposalRequest) => void;
  onShare: () => void;
  proposal: Proposal;
}

const EditorHeader = <T extends HTMLDivElement>(
  {
    proposal,
    approvalRequest,
    onChange,
    onShare,
    isDisabled = false,
    isSaving,
  }: Props,
  forwardedRef: ForwardedRef<T>
) => {
  const canUpdate = isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');
  const { tk } = useTranslation();
  const isTemplate = isProposalTemplate(proposal);
  const isRenewalProposal = isRenewalProposalType(proposal);
  const isChangeProposal = isChangeProposalType(proposal);
  const navigate = useNavigate();
  const [activityDialogOpen, setActivityDialogOpen] = useState(false);
  const [sharePopover, setSharePopover] = useState(false);
  const isDraft = isDraftProposalStatus(proposal);
  const isApproved = isApprovedProposalStatus(proposal);
  const [validationAction, setValidationAction] =
    useState<ValidationAction>(undefined);
  const { isValid: isSubscribable } = useValidateProposal(
    proposal,
    'create-subscription'
  );
  const { isValid } = useValidateProposal(proposal);
  const hasProposalRecipients = proposalHasRecipients(proposal);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [isNew, setIsNew] = useState(!!query.get('first'));
  const showToast = useToast();
  const queryClient = useQueryClient();
  const isAdmin = useIsCurrentUserAdmin();
  const { data: companyInfo } = useCompanyInfo();

  const { enhancedSpans } = useFlags();
  const isAutoGenerated = isAutoRenewedGeneratedProposal(proposal);
  const areProductsNotLinked =
    getIsProductLinkRequired(proposal) &&
    proposal.proposalItems.some((item) => {
      return getIsProductNotLinked(item, proposal.externalSource?.sourceType);
    });

  const salesforceWarning =
    'Proposal includes line items with products that have not been linked to Salesforce. Line items with products that have not been linked will not sync to Salesforce. Contact your Cacheflow admin to link products and re-sync this proposal once the update has been completed.';
  const hubspotWarning =
    'HubSpot integration requires that all products in a proposal must be linked to your product catalog before syncing line items. Contact your Cacheflow admin to link products and re-sync this proposal once the update has been completed.';

  const warningMessage = (() => {
    switch (proposal.externalSource?.sourceType) {
      case 'salesforce':
        return salesforceWarning;
      case 'hubspot':
        return hubspotWarning;
      default:
        return '';
    }
  })();

  if (!proposal.id) {
    // This will automatically go away when id is non-null in the data contract
    throw new Error('Proposal id is not set which is not possible');
  }

  const { mutate: acceptProposal } = useAcceptProposal(
    proposal.id,
    () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      showToast.success('Proposal has been accepted');
    },
    () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      showToast.error('Proposal failed to accept');
    },
    queryClient
  );

  const { data: contract } = useContract(proposal.contract?.id);

  const isContractCancelled = (contract?.status ?? '') === 'cancelled';

  const { mutate: acceptProposalForCreate } = useAcceptProposal(
    proposal.id,
    (data: Proposal) => {
      if (!data.contract?.id) {
        throw new Error('Expected contract in onSuccess for useAcceptProposal');
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      showToast.success('Subscription has been created');
      navigate(`/subscriptions/${data.contract.id}`);
    },
    () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      showToast.error('Subscription failed to create');
    },
    queryClient
  );

  const approvalRequiredForProposal = useApprovalRequiredForProposal(
    proposal.id
  );

  const [confirmationModalToShow, setConfirmationModalToShow] = useState('');
  const [isValidationDrawerOpen, setIsValidationDrawerOpen] = useState(false);

  const isNotExpired = !isExpiredProposalStatus(proposal);
  const isExpired = isExpiredProposalStatus(proposal);

  const isDraftAndNoApprovalRequired =
    !approvalRequiredForProposal && isDraftProposalStatus(proposal);

  const isApprovedAndApprovalRequired =
    approvalRequiredForProposal && isApprovedProposalStatus(proposal);

  // An edge case in which approvals were enabled, and a proposal was approved, and then before
  // The proposal was shared, approvals were disabled. Which resulted in a non-sharable proposal
  const isApprovedAndNoApprovalRequired =
    !approvalRequiredForProposal && isApprovedProposalStatus(proposal);

  const hasAcceptedStatus = hasAnAcceptedProposalStatus(proposal);

  const isPublished = isProposalPublished(proposal.status);

  const isSharable: boolean =
    isNotExpired &&
    (isDraftAndNoApprovalRequired ||
      isApprovedAndApprovalRequired ||
      isApprovedAndNoApprovalRequired ||
      hasAcceptedStatus ||
      isPublished);

  const proposalIcon = () => {
    const IconComponent = getIconBasedOnProposalType(proposal);

    return <IconComponent {...SIZE_LARGE} />;
  };

  const handleDeleteError = () => {
    showToast.error('Proposal deletion failed');
  };

  const handleDeleteSuccess = () => {
    navigate(PROPOSALS);
  };

  const { mutate: deleteProposal } = useDeleteProposal(
    proposal.id,
    handleDeleteSuccess,
    handleDeleteError,
    queryClient
  );

  const handleToggleProposalStatus = (status: Proposal['status']) => {
    onChange({ status });
  };

  const onShareClick = () => {
    if ((isDraft || isApproved) && hasProposalRecipients && isValid) {
      handleToggleProposalStatus(getPublishStatus(proposal));
    }

    if (isValid) {
      onShare();
    } else {
      setIsValidationDrawerOpen(true);
    }
  };

  const onShareTemplateClick = () => {
    if (isValid) {
      onChange({ status: 'active' });
    }
  };

  const onNameChange = (updateProposalRequest: CreateProposalRequest) => {
    onChange(updateProposalRequest);
    setIsNew(false);
  };

  const openSubscriptionCreateModal = () => {
    setSharePopover(false);
    setValidationAction('create-subscription');

    if (!isSubscribable) {
      setIsValidationDrawerOpen(true);
      return;
    }

    setConfirmationModalToShow('create');
  };

  const onClickCreateSubscriptionFromProposal = () => {
    const foundSchedule = findSchedule(proposal);

    const paymentId = arrayTop(foundSchedule?.paymentMethodIds);

    acceptProposalForCreate({
      billingMethodRequest: {
        paymentMethodId: paymentId,
      },
    });
  };

  const actionBarItemIsDisabled = !isValid || isAutoGenerated || isExpired;
  const selectRecipientDropdownOptions = () => {
    if (isRenewalProposal && isAdmin) {
      return (
        <div className={styles.autoSubscriptionDropdown}>
          <p className={styles.message}>
            If you have permission to accept the renewal on behalf of the buyer
            you may do so.
          </p>
          <Button
            className={styles.button}
            label="Accept renewal now"
            onClick={() => setConfirmationModalToShow('renewal')}
          />
        </div>
      );
    }

    if (isChangeProposal && isAdmin) {
      return (
        <div className={styles.autoSubscriptionDropdown}>
          <p className={styles.message}>
            If you have permission to accept the change(s) on behalf of the
            buyer you may do so.
          </p>
          <Button
            className={styles.button}
            label="Accept change(s)"
            onClick={() => setConfirmationModalToShow('change')}
          />
        </div>
      );
    }

    if (
      proposal.proposalType === 'initial' &&
      !hasAnAcceptedProposalStatus(proposal) &&
      isAdmin
    ) {
      return (
        <div className={styles.autoSubscriptionDropdown}>
          <Button
            block
            label={tk('Create subscription')}
            onClick={openSubscriptionCreateModal}
            type="primary"
          />
        </div>
      );
    }

    return null;
  };

  const renderShareButton = () => {
    const dataTestId = 'share-proposal';
    const labelText = 'Share';

    if (isTemplate) {
      return (
        <Button
          className={canUpdate ? undefined : ' invisible'}
          dataTestId={dataTestId}
          isDisabled={isDisabled || !isDraft}
          label="Share with team"
          onClick={onShareTemplateClick}
        />
      );
    }

    if (isValid && !hasAnAcceptedProposalStatus(proposal)) {
      return (
        <DropdownButton
          className={canUpdate ? undefined : ' invisible'}
          dataTestId={dataTestId}
          isDisabled={!isSharable}
          label={labelText}
          onClick={onShareClick}
          options={selectRecipientDropdownOptions()}
          popoverProps={{
            isOpen: sharePopover,
            onOpenChange: setSharePopover,
            className: canUpdate ? undefined : 'invisible',
          }}
        />
      );
    }

    if (
      isSharable &&
      !isValid &&
      !canAcceptProposalForBuyer(proposal, isAdmin)
    ) {
      return (
        <Button
          className={canUpdate ? undefined : ' invisible'}
          dataTestId={dataTestId}
          label={labelText}
          onClick={() => setIsValidationDrawerOpen(true)}
        />
      );
    }

    return (
      <Button
        className={canUpdate ? undefined : ' invisible'}
        dataTestId={dataTestId}
        isDisabled={!isSharable || isAutoGenerated}
        label={labelText}
        onClick={onShareClick}
      />
    );
  };

  return (
    <>
      <div className={styles.editorHeader} ref={forwardedRef}>
        <div className={styles.whiteBar}>
          <div className={styles.side}>
            <Link data-testid="proposals-back-link" to={PROPOSALS}>
              {proposalIcon()}
            </Link>

            <span className={styles.proposalName}>
              <Editable
                as="p"
                content={proposal.name}
                dataTestId="proposal-name"
                isDisabled={isDisabled}
                maxContentLength={100}
                onValueChange={(value) => onNameChange({ name: value })}
              />
            </span>

            <span className={styles.updatedAt}>
              {isSaving ? 'Saving...' : formatTimeAgo(proposal.updatedAt)}
            </span>
          </div>

          <div className={styles.side}>
            <StatusLabel
              displayStatus={proposal.displayStatus}
              feature="proposal"
              status={proposal.status}
            />

            <ActionBar>
              {hasAnAcceptedProposalStatus(proposal) &&
                proposal.contract?.id && (
                  <SubscriptionButton
                    contractId={proposal.contract.id}
                    isDisabled={false}
                  />
                )}

              {!isTemplate && (
                <PdfButton
                  fetchFunction={async () =>
                    apiClient.getProposalPdf(
                      proposal.id,
                      {},
                      { format: 'blob' }
                    )
                  }
                  fileName={buildFileName(companyInfo ?? {}, proposal)}
                  isCancelled={isContractCancelled}
                  isDisabled={actionBarItemIsDisabled}
                  objectName="Proposal"
                  postDownload={async () =>
                    createProposalEvent(queryClient, proposal.id, {
                      eventType: 'seller_downloaded',
                    })
                  }
                />
              )}

              {!isTemplate && (
                <WordButton
                  fetchFunction={async () =>
                    ssrAxios.post<Blob>(
                      '/render-word',
                      {
                        sellerName: companyInfo?.name,
                        address: companyInfo?.billingAddress,
                        logoUrl:
                          companyInfo?.logo?.latestVersion?.url ??
                          companyInfo?.logoUrl,
                        tin: companyInfo?.tin,
                        isEnhancedSpan: enhancedSpans,
                        data: {
                          proposal,
                        },
                      },
                      {
                        responseType: 'blob',
                      }
                    )
                  }
                  fileName={buildFileName(companyInfo ?? {}, proposal, '.docx')}
                  isCancelled={isContractCancelled}
                  isDisabled={actionBarItemIsDisabled}
                  objectName="Proposal"
                  postDownload={async () =>
                    createProposalEvent(queryClient, proposal.id, {
                      eventType: 'seller_downloaded',
                    })
                  }
                />
              )}

              <ProposalPreviewButton
                isDisabled={actionBarItemIsDisabled}
                proposal={proposal}
              />

              {isProposalCancellable(proposal) && (
                <ProposalCancelButton proposalId={proposal.id} />
              )}

              <ProposalActivityButton
                activityDialogOpen={activityDialogOpen}
                isDisabled={isAutoGenerated}
                proposalId={proposal.id}
                setActivityDialogOpen={setActivityDialogOpen}
              />

              <ActionBarButton className={canUpdate ? undefined : ' invisible'}>
                <ProposalOptionsButton editorContext proposal={proposal} />
              </ActionBarButton>
            </ActionBar>

            {renderShareButton()}
          </div>
        </div>
        <ProposalStatus
          approvalRequest={approvalRequest}
          isDisabled={isDisabled}
          onChange={onChange}
          onShareClick={isTemplate ? onShareTemplateClick : onShareClick}
          proposal={proposal}
        />
        {areProductsNotLinked && (
          <Alert
            banner
            className={styles.alertBreakout}
            closable
            message={warningMessage}
            type="error"
          />
        )}
        <div className={styles.externalEntityLink}>
          <ExternalEntityLink entity={proposal} showId />
        </div>
      </div>
      {isNew && isTemplate && (
        <NameModal
          onChange={onNameChange}
          onClose={deleteProposal}
          proposalName={proposal.name}
        />
      )}

      <SubscriptionRenewalModal
        isOpen={confirmationModalToShow === 'renewal'}
        onClose={() => {
          setConfirmationModalToShow('');
        }}
        onConfirm={() => {
          acceptProposal({});
          setConfirmationModalToShow('');
        }}
      />

      <SubscriptionChangeModal
        isOpen={confirmationModalToShow === 'change'}
        onClose={() => {
          setConfirmationModalToShow('');
        }}
        onConfirm={() => {
          acceptProposal({});
          setConfirmationModalToShow('');
        }}
      />

      <SubscriptionCreateModal
        isOpen={confirmationModalToShow === 'create'}
        onClose={() => {
          setConfirmationModalToShow('');
        }}
        onConfirm={() => {
          onClickCreateSubscriptionFromProposal();
          setConfirmationModalToShow('');
        }}
        onCoreChange={onChange}
        proposal={proposal}
      />

      <VerifyProposal
        action={validationAction}
        isOpen={isValidationDrawerOpen}
        onClose={() => {
          setValidationAction(undefined);
          setIsValidationDrawerOpen(false);
        }}
        proposal={proposal}
      />
    </>
  );
};

export default forwardRef(EditorHeader);
