import React from 'react';
import { ForceUser, formatDatetime, SalesforceConfig, Type } from 'common';

interface Props {
  connectorConfigValue?: SalesforceConfig;
  salesforceUser: ForceUser;
}

const SalesforceDetails: React.FC<Props> = ({
  connectorConfigValue,
  salesforceUser,
}) => {
  return (
    <>
      <div className="my-4">
        <div>
          {salesforceUser.FirstName} {salesforceUser.LastName} (
          {salesforceUser.Username})
        </div>
      </div>
      <h3>Cacheflow for Salesforce Package</h3>
      <div className="my-4">
        {salesforceUser.PackageInstalledAt ? (
          <div>
            <div>Version: {salesforceUser.PackageVersion}</div>
            <div>
              Installed: {formatDatetime(salesforceUser.PackageInstalledAt)}
            </div>
            {connectorConfigValue?.priceBook2Id && (
              <div>
                Cacheflow price book Id: {connectorConfigValue.priceBook2Id}
              </div>
            )}
          </div>
        ) : (
          <div>
            <Type size={18} paragraph>
              Cacheflow for Salesforce package not installed
            </Type>
          </div>
        )}
      </div>
    </>
  );
};

export default SalesforceDetails;
